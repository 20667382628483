<!-- 001 -->
<template>
  <div class="car-details-page">
    <!-- 组件 200020000 车1盒子 -->
    <div v-if="configuration && configuration.DetailImage">
      <!-- 仅在 configuration 不为 null 或 undefined 时渲染子组件 -->
      <CarDetailsBox :detail-image="configuration.DetailImage" :ProductImage="configuration.ProductImage" 
                    :detail-description="configuration.DetailDescription" :Configuration="configuration.Configuration" :configID="configID" />
    </div>
    <!-- 编号: 200030000 产品主要介绍盒子 -->
    <!-- <ProductMainBox v-if="configuration" :ProductImage="configuration.ProductImage" :Configuration="configuration.Configuration" :configID="configID"/> -->
    <!-- 组件 200040000 性能介绍盒子
    <PerformanceBox :configID="configID"/> -->
    <!-- 组件 200050000 细节介绍盒子 -->
     <div style="background-color: black;height: 5vh;width: 100%;"></div>
     <div v-for="(detail, index) in details" :key="index">
      <!-- 在第二张图上添加额外内容 -->
      <DetailBox 
        :OtherImage="detail.OtherImage || detail.PerformanceImage"
        :OtherText="detail.OtherText" 
        :AdditionalContent="index === 1 ? additionalText : null" 
        :configID="configID" 
      />
    </div>

    <div style="height: 150px;background-color: black;"></div>
  </div>
</template>

<script setup>
import CarDetailsBox from './CarDetailsPage/CarDetailsBox.vue';
// import ProductMainBox from '../components/ProductMainBox.vue';
// import PerformanceBox from './CarDetailsPage/PerformanceBox.vue';
import DetailBox from './CarDetailsPage/DetailBox.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const store = useStore();
const route = useRoute();
const configID = Number(route.params.configID);
const configuration = computed(() => store.getters.configuration);
console.log('configuration:',configuration.value)

const additionalText = ref('敬请期待');


// 获取当前语言环境
const { locale } = useI18n();

// 根据 locale 动态切换图片来源
const details = computed(() => {
  if (locale.value === 'zh') {
    // 中文版
    console.log('中文')
    return store.getters.textImageList || [];
  } else{
    // 英文版
    console.log('英文')
    return store.getters['performance/performanceList'] || [];
  }
});

// 在组件挂载时获取图文列表
onMounted(() => {
  console.log('Fetched details configID:', configID);
  store.dispatch('fetchTextImageList', configID);
  store.dispatch('fetchConfigurationByID', configID);
  store.dispatch('performance/fetchPerformanceList', configID);
  console.log('图片：',details)

  // 调用 parameters/fetchParameters
  store.dispatch('parameters/fetchParameters', configID);

  // 监听 parameterSections 的变化，将数据传递给子组件
  watch(
    () => store.getters['parameters/parameterSections'],
    (newSections) => {
      additionalText.value = newSections;  // 直接传递参数数据到 additionalText
      console.log('Fetched additionalText:', additionalText.value);
    },
    { immediate: true }
  );
});
</script>

<style scoped>
.car-details-page {
  /* 页面样式 */
}
</style>
