<template>
  <div class="bottom-bar">
    <!-- 底部导航栏内容 -->
    <div class="bottom-bar-content">
      <a
        :class="['nav-item', { active: activeItem === 'home' }]"
        @click="navigateTo('home', 'MainPage')"
      >
        <img
          :src="activeItem === 'home' ? require('../assets/home-on.svg') : require('../assets/home.svg')"
          alt="{{ $t('message.home') }}"
          class="nav-icon"
        />
        <span>{{ $t('message.home') }}</span>
      </a>
      <a
        :class="['nav-item', { active: activeItem === 'products' }]"
        @click="navigateTo('products', 'VehicleParts')"
      >
        <img
          :src="activeItem === 'products' ? require('../assets/product-icon-on.svg') : require('../assets/product-icon.svg')"
          alt="{{ $t('message.products') }}"
          class="nav-icon"
        />
        <span>{{ $t('message.products') }}</span>
      </a>
      <a
        :class="['nav-item', { active: activeItem === 'explore' }]"
        @click="navigateTo('explore', 'ContactPage')"
      >
        <img
          :src="activeItem === 'explore' ? require('../assets/explore-icon-on.svg') : require('../assets/explore-icon.svg')"
          alt="{{ $t('message.companyIntro') }}"
          class="nav-icon"
        />
        <span>{{ $t('message.explore') }}</span>
      </a>
      <!-- <a
        :class="['nav-item', { active: activeItem === 'cart' }]"
        @click="navigateTo('cart', 'CartPage')"
      >
        <img
          :src="activeItem === 'cart' ? require('../assets/cart-icon-on.svg') : require('../assets/cart-icon.svg')"
          alt="{{ $t('message.cart') }}"
          class="nav-icon"
        />
        <span>{{ $t('message.cart') }}</span>
      </a> -->
    </div>
  </div>
</template>

  
  <script setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  
  const activeItem = ref('home'); // 默认选中第一个导航项
  const router = useRouter();
  
  const navigateTo = (item, routeName) => {
    activeItem.value = item; // 更新选中的导航项
    router.push({ name: routeName }); // 跳转到指定的路由页面
  };
  </script>
  
  <style scoped>
  .bottom-bar {
    background-color: #000; /* 纯黑色底色 */
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
  }
  
  .bottom-bar-content {
    display: flex;
    justify-content: space-around; /* 平均分布导航项 */
    align-items: center;
  }
  
  .nav-item {
    color: #aaa; /* 默认图标和文字颜色 */
    text-decoration: none;
    text-align: center;
    font-size: 12px;
    flex-direction: column; /* 使图标和文字垂直排列 */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
  }
  
  .nav-icon {
    width: 24px; /* 图标宽度 */
    height: 24px; /* 图标高度 */
    margin-bottom: 5px;
  }
  
  .nav-item.active {
    color: #007aff; /* 选中项的颜色，亮蓝色 */
  }
  
  .nav-item:hover {
    color: #007aff; /* 悬停时图标和文字的颜色 */
  }
  </style>
  