<template>
  <div class="contact-page">
    <!-- 顶部组件 -->
    <HeaderComponent @toggle-side-nav="toggleSideNav" />
    <SideNavComponent :isVisible="isSideNavVisible" @close-side-nav="toggleSideNav" />
    <div style="height: 10vh;"></div>

    <!-- 新增展示图片的div -->
    <div class="contact-image">
      <img src="../images/contact-img.jpg" alt="联系图片">

      <!-- 联系方式等放置在图片的左下角 -->
      <div class="contact-info-overlay">
        <!-- WhatsApp 联系方式 -->
        <div class="contact-method">
          <img class="icon" src="../images/whatsapp.png" alt="WhatsApp">
          <p class="contact-account" @click="copyToClipboard(companyInfo.WhatsappNumber)">{{ companyInfo.WhatsappNumber }}</p>
        </div>
        <!-- 座机 -->
        <div v-if="companyInfo.LandlineNumber" class="contact-method">
          <img class="icon" src="../images/phone-icon.png" alt="座机">
          <p class="contact-account" @click="copyToClipboard(companyInfo.LandlineNumber)">{{ companyInfo.LandlineNumber }}</p>
        </div>
        <!-- 随机展示一个微信账号 -->
        <div v-if="selectedWechat" class="contact-method">
          <img class="icon" src="../images/微信.png" alt="微信">
          <p class="contact-account" @click="copyToClipboard(selectedWechat.wechatID)">{{ selectedWechat.wechatID }}</p>
        </div>
        <!-- 展示配对的邮箱 -->
        <div class="contact-method">
          <img class="icon" src="../images/邮箱.png" alt="邮箱">
          <p class="contact-account" @click="copyToClipboard(companyEmail)">{{ companyEmail }}</p>
        </div>
      </div>

      <!-- 微信二维码始终显示在图片中间偏上位置，添加v-if判断 -->
      <div v-if="selectedWechat && selectedWechat.qrCode" class="qr-code-display">
        <img :src="selectedWechat.qrCode" alt="微信二维码">
      </div>
    </div>

    <!-- 引入 ContactForm 子组件 -->
    <ContactForm />

    <!-- 公司简介部分 -->
    <div class="company-intro-section">
      <h2 class="intro-title">{{ $t('message.companyIntro') }}</h2>
      <p class="company-intro">{{ companyIntroData[currentLocale] }}</p>
    </div>
  </div>
  <div style="height: 10vh;"></div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import HeaderComponent from '../components/MainHeader.vue';
import SideNavComponent from '../components/SideNavComponent.vue';
import ContactForm from '../components/ContactForm.vue'; // 引入子组件
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const companyIntroData = ref({}); 

const isSideNavVisible = ref(false);
const selectedWechat = ref(null);  // 选择一个微信账号
const companyEmail = ref('');  // 选择一个邮箱
const { locale } = useI18n();
const currentLocale = computed(() => locale.value); 

const toggleSideNav = () => {
  isSideNavVisible.value = !isSideNavVisible.value;
};

const store = useStore();
const companyInfo = computed(() => store.getters.companyInfo);

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
watch(companyInfo, (newValue) => {
  if (newValue && newValue.CompanyIntro) {
    companyIntroData.value = JSON.parse(newValue.CompanyIntro); 
  }
});

onMounted(() => {
  store.dispatch('fetchCompanyInfo').then(() => {
    const wechatData = [
      { id: 1, wechatID: companyInfo.value.WechatID1, qrCode: companyInfo.value.WechatQRCode1, email: companyInfo.value.ContactEmail },
      { id: 2, wechatID: companyInfo.value.WechatID2, qrCode: companyInfo.value.WechatQRCode2, email: companyInfo.value.ContactEmail2 },
      { id: 3, wechatID: companyInfo.value.WechatID3, qrCode: companyInfo.value.WechatQRCode3, email: companyInfo.value.ContactEmail3 },
      { id: 4, wechatID: companyInfo.value.WechatID4, qrCode: companyInfo.value.WechatQRCode4, email: companyInfo.value.ContactEmail4 }
    ].filter(account => account.wechatID); // 过滤掉空的微信账号

    const shuffledWechatData = shuffleArray(wechatData);
    selectedWechat.value = shuffledWechatData[0];  // 随机选择一个微信账号
    companyEmail.value = shuffledWechatData[0].email;  // 邮箱与微信配对
  });
});

// 复制到剪贴板
const copyToClipboard = (text) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(() => {
      alert('已复制到剪贴板');
    }).catch(err => {
      console.error('复制失败', err);
    });
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.left = '-9999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      alert('已复制到剪贴板');
    } catch (err) {
      console.error('复制失败', err);
    }

    document.body.removeChild(textArea);
  }
};


</script>

<style scoped>
/* 联系页面样式 */
.contact-page {
  /* 整体页面样式 */
}

.contact-image {
  position: relative;
  width: 100%;
  height: auto;
}

.contact-image img {
  width: 100%; /* 确保图片占据100%的宽度 */
  height: auto; /* 自动调整高度以保持图片的原始比例 */
  display: block;
}

.contact-info-overlay {
  position: absolute;
  top: 71%; /* 放置在图片左下角 */
  left: 11%;
  color: white; /* 字体颜色设置为白色 */
  font-size: 14px; /* 调整字体大小为14px */
  font-family: PingFangSC-Regular;

}

.contact-info-overlay .contact-method {
  display: flex;
  margin: 0; /* 去掉外部的所有间距 */
  padding: 0;
  align-items: center;
}

.contact-info-overlay .icon {
  width: 10%;
  height: 10%;
  margin-right: 1%;
}

.contact-info-overlay .contact-account {
  color: white; /* 确保字体为白色 */
  margin: 0; /* 去掉文字的外部间距 */
  padding: 3%; /* 去掉文字的内部填充 */
}

/* 微信二维码显示在图片的中间偏上位置 */
.qr-code-display {
  position: absolute;
  top: 41.5%; /* 中间偏上的位置 */
  left: 56%;
  transform: translate(-50%, -20%);
  text-align: center;
}

.qr-code-display img {
  max-width: 75%;
  height: auto; 
}

.qr-code-tip {
  margin-top: 15px;
  font-size: 16px;
  color: white;
}

.company-intro-section {
  margin: 20px 30px; /* 上下边距为20px，左右边距为30px */
  padding: 10px; /* 内边距 */
  background-color: #fcfffb; /* 背景色，提升可读性 */
  border-radius: 8px; /* 圆角效果 */
}

.intro-title {
  font-size: 24px; /* 标题字体大小 */
  font-weight: bold; /* 标题加粗 */
  margin-bottom: 15px; /* 标题与简介内容之间的距离 */
  color: #333; /* 标题颜色 */
}

.company-intro {
  font-size: 16px; /* 简介内容的字体大小 */
  line-height: 1.6; /* 增加行高，提升可读性 */
  color: #555; /* 简介文字的颜色 */
}

</style>
