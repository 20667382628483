<template>
	<div class="header">
		<!-- logo-图标 (编号: 100010001) -->
		<img :src="logoUrl" alt="Logo" class="logo" @click="goToMainPage" />
		<!-- 侧拉导航栏按钮 (编号: 100010002) -->
		<button class="side-nav-button" @click="toggleSideNav">{{ $t('message.toggleNav') }}</button>
	</div>
</template>

<script>
	import { useRouter, useRoute } from "vue-router";
	import { ref, onMounted } from "vue";
	import axios from "axios";


	export default {
		name: "HeaderComponent",
		setup() {
			const router = useRouter();
			const logoUrl = ref("");
			const route = useRoute();  

			const goToMainPage = () => {
				router.push({ name: "MainPage" });
			};
			// 动态获取 logo，根据路径判断
			const fetchLogo = async () => {
				if (route.path.includes('/user')) {
					// 如果路径包含 /user，使用远程获取的 logo
					try {
						const response = await axios.get("https://www.etong-ev.com/api/yitong/company/logo");
						logoUrl.value = response.data.logo;
						localStorage.setItem("companyLogo", response.data.logo);
					} catch (error) {
						console.error("Failed to fetch company logo:", error);
					}
				} else if (route.path.includes('/hongya')) {
					// 如果路径包含 /hongya，使用静态资源中的 logo
					logoUrl.value = require('@/assets/hongyalogo.png');  // 静态logo路径
				}
			};

			// 监听页面加载时的路径
			onMounted(() => {
				fetchLogo();
			});

			return {
				goToMainPage,
				logoUrl,
			};
		},
		methods: {
			toggleSideNav() {
				this.$emit("toggle-side-nav");
			},
		},
	};
</script>

<style scoped>
	.header {
    position: fixed; /* 固定在页面顶部 */
    top: 0;
    left: 0;
    width: 100%; /* 占满整个宽度 */
    background-color: #000000; /* 黑色背景 */
    padding: 15px 20px; /* 内边距，适当增加留白 */
    z-index: 2;
    display: flex;
    justify-content: space-between; /* 左右对齐 */
    align-items: center; /* 垂直居中 */
    box-sizing: border-box; /* 确保内边距在盒模型内 */
    min-height: 80px; /* 最小高度，确保盒子足够大 */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 增加阴影，提升视觉层次 */
}

.logo {
    width: 144px;
    height: 60px;
    cursor: pointer; /* 增加手型指针以指示可点击 */
    transition: transform 0.3s; /* 鼠标悬停时的缩放效果 */
}

.logo:hover {
    transform: scale(1.05); /* 鼠标悬停时略微放大 */
}

.side-nav-button {
    width: 100px;
    height: 40px;
    border-radius: 20px; /* 圆角按钮 */
    background-color: rgba(255, 255, 255, 0.1); /* 半透明背景色 */
    color: #ffffff; /* 按钮文字颜色 */
    font-size: 14px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.3); /* 边框为浅白色 */
    cursor: pointer; /* 增加手型指针 */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
    transition: background-color 0.3s, box-shadow 0.3s; /* 过渡效果 */
}

.side-nav-button:hover {
    background-color: rgba(255, 255, 255, 0.3); /* 悬停时背景色变化 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 悬停时增加阴影 */
}

</style>
